import React from 'react';
import { stopAnimation, clearAnimation } from "./AnimationCanvas";
const handlePrint = () => {
  stopAnimation();
  clearAnimation();
  setTimeout(() => {
    window.print();
  }, 300);
};
const Hobby = () => (
  <div>
    <div className="row">
      <div className="col-sm-4 col-xl-3">
        <div className="card bg-custom text-light">
          <div className="card-body">
            <h6 className="card-title">HOBBY</h6>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-xl-9">
        <div className="card">
          <div className="card-body">
            <div className="tech-container">
              <div className="tech-item">Running</div>
              <div className="tech-item">Reading programming books</div>
            </div>
          </div>
        </div>
        <div>

          <button
            type="button"
            className="btn printButton btn-sm float-right mt-2"
            onClick={handlePrint}
            style={{color: '#fff'}}
          >
            <svg
              className="print-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 8 8"
            >
              <path stroke='#fff' color='#fff' d="M2 0v2h4v-2h-4zm-1.91 3c-.06 0-.09.04-.09.09v2.81c0 .05.04.09.09.09h.91v-2h6v2h.91c.05 0 .09-.04.09-.09v-2.81c0-.06-.04-.09-.09-.09h-7.81zm1.91 2v3h4v-3h-4z" />
            </svg>
            Print
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default Hobby;
