import React from 'react';

// Component for listing applications with links
const ApplicationsList = () => (
  <div>
    <h5>Projects:</h5>
    <ul className="list-group list-group-flush some-transparency">
      <li className="list-group-item rounded">
        <a href="https://beelog.digital/" target="_blank" rel="noopener noreferrer">BeeLog (iOS/Android)</a>
      </li>
      <li className="list-group-item rounded">
        <a href="https://bizradar.gov.md/" target="_blank" rel="noopener noreferrer">BizRadar (iOS/Android)</a>
      </li>
      <li className="list-group-item rounded">
        <a href="https://apps.apple.com/md/app/taskeer/id1526822416" target="_blank" rel="noopener noreferrer">Taskeer (iOS/Android)</a>
      </li>
      <li className="list-group-item rounded">
        <a href="https://apps.apple.com/md/app/hd-pro/id6450000772" target="_blank" rel="noopener noreferrer">HD-PRO (iOS/Android)</a>
      </li>
    </ul>
  </div>
);

// Component for listing skills and responsibilities
const SkillsAndResponsibilities = () => (
  <div>
    <h5>Skills and Responsibilities:</h5>
    <ul className="list-group list-group-flush some-transparency">
      <li className="list-group-item rounded">
        • Architected and developed scalable mobile applications from the ground up.
      </li>
      <li className="list-group-item rounded">
        • Implemented responsive and adaptive designs for cross-platform compatibility.
      </li>
      <li className="list-group-item rounded">
        • Performed thorough testing, debugging, and optimization of applications, including unit and e2e testing, to ensure code quality and reliability.
      </li>
      <li className="list-group-item rounded">
        • Led technical discussions and decision-making processes within the team to define architecture and best practices.
      </li>
      <li className="list-group-item rounded">
        • Identified, diagnosed, and resolved complex issues in mobile applications, including performance bottlenecks, crashes, and bugs, to ensure smooth user experiences.
      </li>
      <li className="list-group-item rounded">
        • Conducted regular code reviews for quality assurance and adherence to coding standards.
      </li>
      <li className="list-group-item rounded">
        • Collaborated with the creative team, including designers and product managers, to ensure alignment and achievement of project objectives.
      </li>
      <li className="list-group-item rounded">
        • Ensured applications met compliance standards and followed best practices for security and performance.
      </li>
      <li className="list-group-item rounded">
        • Stayed current with industry trends, technologies, and best practices to continuously improve development processes and application performance.
      </li>
    </ul>
  </div>
);


// Main WorkExperience component
const WorkExperience = () => (
  <div>
    <div className="row">
      <div className="col-sm-4 col-xl-3">
        <div className="card bg-custom text-light">
          <div className="card-body">
            <h6 className="card-title">WORK EXPERIENCE</h6>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-xl-9"></div>
    </div>
    <div className="row">
      <div className="col-sm-4 col-xl-3 mb-3">
        <div className="card">
          <div className="card-body">
            <h6 className="card-title">21.05.2021 – Present</h6>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-xl-9">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">React Native Developer</h5>
            <p>
              "Amigo Studio", Chisinau, Moldova -
              <a href="https://amigo.studio/public/en" target="_blank" rel="noopener noreferrer"> www.amigo.studio/en</a>
            </p>
            <ApplicationsList />
            <SkillsAndResponsibilities />
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-4 col-xl-3 mb-3">
        <div className="card">
          <div className="card-body">
            <h6 className="card-title">01.09.2020 – 21.05.2021</h6>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-xl-9">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">React Native Developer</h5>
            <p>
              "IT Smart Systems", Chisinau, Moldova -
              <a href="http://www.itsmartsystems.eu/" target="_blank" rel="noopener noreferrer"> www.itsmartsystems.eu</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-4 col-xl-3 mb-3">
        <div className="card">
          <div className="card-body">
            <h6 className="card-title">01.08.2019 – 01.09.2020</h6>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-xl-9">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">Front-End Developer</h5>
            <p>
              "IT Smart Systems", Chisinau, Moldova -
              <a href="http://www.itsmartsystems.eu/" target="_blank" rel="noopener noreferrer"> www.itsmartsystems.eu</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default WorkExperience;
