import React from 'react';

const Languages = () => (
  <div>
    <div className="card mb-1 bg-custom text-light">
      <div className="card-body">
        <h5 className="card-title center">Languages</h5>
      </div>
    </div>
    <div className="card mb-3">
      <div className="tech-container" style={{marginLeft: "1.25rem"}}>
        <div className="tech-item">Romanian - Native</div>
        <div className="tech-item">Russian - Native</div>
        <div className="tech-item">English - Advanced</div>
      </div>
    </div>
  </div>
);

export default Languages;
