import React from 'react';

const Strengths = () => (
  <div>
    <div className="row">
      <div className="col-sm-4 col-xl-3">
        <div className="card bg-custom text-light">
          <div className="card-body">
            <h6 className="card-title">STRENGTHS</h6>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-xl-9">
        <div className="card">
          <div className="card-body">
            <div className="tech-container">
              <div className="tech-item">
                <i className="bi bi-people"></i > Flexible and adaptable
              </div>
              <div className="tech-item">
                <i className="bi bi-check-circle"></i> Goal-oriented and result-driven
              </div>
              <div className="tech-item">
                <i className="bi bi-person"></i> Highly autonomous and self-motivated
              </div>
              <div className="tech-item">
                <i className="bi bi-emoji-smile"></i> Positive and proactive attitude
              </div>
              <div className="tech-item">
                <i className="bi bi-calendar-check"></i> Personal organization skills
              </div>
              <div className="tech-item">
                <i className="bi bi-chat"></i> Strong communication skills
              </div>
              <div className="tech-item">
                <i className="bi bi-book"></i> Eager and quick to learn new technologies
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Strengths;
