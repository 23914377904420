import React from 'react';
import './index.css';
import ImageContainer from './components/ImageContainer';
import Languages from './components/Languages';
import ToolsTechnologies from './components/ToolsTechnologies';
import IndustryKnowledge from './components/IndustryKnowledge';
import WorkExperience from './components/WorkExperience';
import StudiesCertificates from './components/StudiesCertificates';
import Strengths from './components/Strengths';
import Hobby from './components/Hobby';
import AnimationCanvas from './components/AnimationCanvas';

function App() {
  return (
    <div className="container my-5">
      <AnimationCanvas />
      <div className="row mt-4">
        <ImageContainer />
        <div className="col-sm-8 col-xl-9">
          <Languages />
          <ToolsTechnologies />
          <IndustryKnowledge />
        </div>
      </div>
      <br /><br />
      <WorkExperience />
      <br /><br />
      <StudiesCertificates />
      <Strengths />
      <Hobby />
    </div>
  );
}

export default App;
