import React, { useEffect } from 'react';

let myArea;

const AnimationCanvas = () => {
  useEffect(() => {
    let arrT = [];
    const words = [
      "React Native", "JavaScript", "TypeScript",
      "REST APIs", "Expo", "Jest", "Detox", "Maestro", "Axios",
      "Redux-Saga", "Redux Toolkit",
      "Firebase", "Reanimated", "OOP", "TDD", "DRY", "KISS", "Agile", "Waterfall"
    ];
    const wordColors = ["#343a40", "#06466A"];
    const maxSpeed = 0.4;

    class FlyingWord {
      constructor(font, color, techn, speed, dirX, dirY, yTop) {
        this.font = font;
        this.color = color;
        this.techn = techn;
        this.speed = speed;
        this.x = this.getRandomXY(0, document.body.clientWidth);
        this.y = this.getRandomXY(0, document.body.clientHeight);
        this.dirX = dirX;
        this.dirY = dirY;
        this.yTop = yTop;
        this.textWidth = 0;
      }

      getRandomXY(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

      update() {
        const ctx = myArea.context;
        ctx.font = this.font;
        ctx.fillStyle = this.color;
        ctx.textAlign = "center";
        ctx.fillText(this.techn, this.x, this.y);
        this.textWidth = ctx.measureText(this.techn).width / 2;
      }

      newPos() {
        if (this.x <= this.textWidth) {
          this.dirX = 1;
        } else if (this.x >= myArea.canvas.width - this.textWidth) {
          this.dirX = -1;
        }

        if (this.y <= this.yTop) {
          this.dirY = 1;
        } else if (this.y >= myArea.canvas.height) {
          this.dirY = -1;
        }

        this.x += this.dirX * (this.speed + 0.6);
        this.y += this.dirY * this.speed;
      }
    }

    function resizeCanvas() {
      myArea.stop();
      myArea.clear();
      startPlay();
    }

    myArea = {
      canvas: document.createElement("canvas"),
      start: function () {
        this.canvas.width = document.body.clientWidth;
        this.canvas.height = document.body.clientHeight + 45;
        this.context = this.canvas.getContext("2d");
        document.body.insertBefore(this.canvas, document.body.childNodes[0]);
        this.interval = setInterval(updateGameArea, 10);
      },
      resizeCanvas: function () {
        resizeCanvas();
      },
      stop: function () {
        clearInterval(this.interval);
      },
      clear: function () {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      }
    };



    function startPlay() {
      arrT = [];
      let wordSpeed = 0;
      for (let i = 0, j = 0; i < words.length; i++) {
        j = i % 2;
        wordSpeed = (Math.random() * maxSpeed) + 0.1;
        arrT[i] = new FlyingWord("30px Arial", wordColors[j], words[i], wordSpeed, Math.random() < 0.5, Math.random() < 0.5, 20);
      }
      myArea.start();
    }

    function updateGameArea() {
      myArea.clear();
      for (let i = 0; i < arrT.length; i++) {
        arrT[i].newPos();
        arrT[i].update();
      }
    }

    startPlay();

    window.addEventListener('resize', myArea.resizeCanvas);

    return () => {
      myArea.stop();
      myArea.clear();
      window.removeEventListener('resize', myArea.resizeCanvas);
    };
  }, []);

  return null;
};

export default AnimationCanvas;

export const stopAnimation = () => {
  if (myArea) {
    myArea.stop();
  }
};

export const clearAnimation = () => {
  if (myArea) {
    myArea.clear();
  }
};

