import React from 'react';

const ToolsTechnologies = () => (
  <div>
    <div className="card mb-1 bg-custom text-light">
      <div className="card-body">
        <h5 className="card-title">Tools & Technologies</h5>
      </div>
    </div>
    <div className="card mb-3">
      <div className="card-body">
        <div className="tech-container">
          <div className="tech-item">React Native</div>
          <div className="tech-item">Expo</div>
          <div className="tech-item">JavaScript</div>
          <div className="tech-item">TypeScript</div>
          <div className="tech-item">Redux</div>
          <div className="tech-item">Redux Toolkit</div>
          <div className="tech-item">Redux-Saga</div>
          <div className="tech-item">Expo Router</div>
          <div className="tech-item">React Navigation</div>
          <div className="tech-item">Maestro</div>
          <div className="tech-item">Jest</div>
          <div className="tech-item">Detox</div>
          <div className="tech-item">Firebase</div>
          <div className="tech-item">Axios</div>
          <div className="tech-item">React Native Reanimated</div>
        </div>
      </div>
    </div>
  </div>
);

export default ToolsTechnologies;
