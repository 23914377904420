import React from 'react';

import reactPng from '../images/react.png';

const IndustryKnowledge = () => (
  <div>
    <div className="card mb-1 bg-custom text-light">
      <div className="card-body">
        <h5 className="card-title">Industry knowledge</h5>
      </div>
    </div>
    <div className="card mb-1">
      <div className="card-body">
        <div className="tech-container">
          <div className="tech-item">Agile & Waterfall Methodologies (including Jira)</div>
          <div className="tech-item">Performance Optimization</div>
          <div className="tech-item">Code Reviews</div>
          <div className="tech-item">OOP</div>
          <div className="tech-item">SOLID</div>
          <div className="tech-item">DRY</div>
          <div className="tech-item">KISS</div>
          <div className="tech-item">Design Patterns</div>
          <div className="tech-item">TDD</div>
          <div className="tech-item">Clean Code</div>
          <div className="tech-item">Unit and e2e testing</div>
          <div className="tech-item">Version Control (Git)</div>
          <div className="tech-item">REST APIs</div>
        </div>
      </div>
    </div>
  </div >
);

export default IndustryKnowledge;
