import React from 'react';

const StudiesCertificates = () => (
  <div>
    <div className="row">
      <div className="col-sm-4 col-xl-3">
        <div className="card bg-custom text-light">
          <div className="card-body">
            <h6 className="card-title">STUDIES & CERTIFICATES</h6>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-xl-9"></div>
    </div>
    <div className="row">
      <div className="col-sm-4 col-xl-3 mb-3">
        <div className="card">
          <div className="card-body">
            <h6 className="card-title">01.09.2018 – 31.05.2023</h6>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-xl-9">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">University degree in Information and Communication Technologies</h5>
            <p>
              Academy of Economic Studies of Moldova, Chisinau -
              <a href="http://www.ase.md/" target="_blank" rel="noopener noreferrer"> www.ase.md</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-4 col-xl-3 mb-3">
        <div className="card">
          <div className="card-body text-center">
            <h6 className="card-title">2019</h6>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-xl-9">
        <div className="card">
          <div className="card-body">
            <ul className="list-group list-group-flush some-transparency">
              <li className="list-group-item rounded">
                Simpozionul ştiinţific internaţional al tinerilor cercetători editia XVII:
                <a href="https://ibn.idsi.md/sites/default/files/imag_file/205-206_3.pdf" target="_blank" rel="noopener noreferrer">
                  APPLICATION OF INFORMATIONAL TECHNOLOGY IN CREATION OF HEALTHY NOURISHMENT
                </a>
              </li>
              <li className="list-group-item rounded">
                Teleconference:
                <a href="https://www.researchgate.net/publication/336304898_Creation_of_Consciousness_Society" target="_blank" rel="noopener noreferrer">
                  Creation of Consciousness Society
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-4 col-xl-3 mb-3">
        <div className="card">
          <div className="card-body text-center">
            <h6 className="card-title">2020</h6>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-xl-9">
        <div className="card">
          <div className="card-body">
            <ul className="list-group list-group-flush some-transparency">
              <li className="list-group-item rounded">
                Simpozionul ştiinţific internaţional al tinerilor cercetători editia XVIII:
                <a href="http://dspace.ase.md/xmlui/bitstream/handle/1234567890/948/Statnik_A_SIMPOZION_19-20_iunie_2020.pdf?sequence=1&isAllowed=y" target="_blank" rel="noopener noreferrer">
                  IMPLEMENTATION OF SECURITY OF INFORMATIONAL TECHNOLOGIES IN BANKING SECTOR
                </a>
              </li>
              <li className="list-group-item rounded">
                Teleconference:
                <a href="https://www.researchgate.net/publication/309154418_5_Slobodean_A_Todoroi_N_Todoroi_D_Moldavian_cloisters_-_Romanian_Mind_Intellect_and_Soul_Society_Consciousness_Computers_Volume_3_Proc_of_the_5th_International_young_researchers_TELECONFERINCE_Conscio" target="_blank" rel="noopener noreferrer">
                  ISSN 2359-7321 ISSN-L 2359-7321
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-4 col-xl-3 mb-3">
        <div className="card">
          <div className="card-body text-center">
            <h6 className="card-title">2021</h6>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-xl-9">
        <div className="card">
          <div className="card-body">
            <ul className="list-group list-group-flush some-transparency">
              <li className="list-group-item rounded">
                Simpozionul ştiinţific internaţional al tinerilor cercetători editia XIX:
                <a href="https://drive.google.com/file/d/1DlF3vedSTyuJa_udrXKIhoco_is8k2H9/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                  EFFICIENCY OF DESIGN PATTERNS IN SOFTWARE ENGINEERING
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-4 col-xl-3 mb-3">
        <div className="card">
          <div className="card-body text-center">
            <h6 className="card-title">2022</h6>
          </div>
        </div>
      </div>
      <div className="col-sm-8 col-xl-9">
        <div className="card">
          <div className="card-body">
            <ul className="list-group list-group-flush some-transparency">
              <li className="list-group-item rounded">
                Simpozionul ştiinţific internaţional al tinerilor cercetători editia XX:
                <a href="https://docs.google.com/document/d/12G1YSauk_n-0PhLtPvlflhD-_yOhMxmF/edit?usp=sharing&ouid=107817055942910032502&rtpof=true&sd=true" target="_blank" rel="noopener noreferrer">
                  THE IMPORTANCE OF USABILITY IN DEVELOPING WEBSITES AND MOBILE APPS
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default StudiesCertificates;
