import React from 'react';
import avatar3 from "../images/avatar3.png"

const ImageContainer = () => (
  <div className="col-sm-4 col-xl-3">
    <div className="card mb-3">
      <div className="card-body photo-card">
        <img
          className="card-img-top mx-auto d-block m-1 img-thumbnail"
          style={{ maxWidth: '360px', height: 'auto' }}
          src={avatar3} // Use the imported image
          alt="Face Photo"
        />
        <p className="card-title text-center"><b>Statnik Aurel</b></p>
        <div className='center'>
          <p className="card-text">
            <span className="dob-emoji" role="img" aria-label="date of birth">📅  </span>
            05.10.2000
          </p>
        </div>
        <div className='center'>
          <span className="location-emoji" role="img" aria-label="location">📍  </span>
          Moldova, Chisinau
        </div>
        {/* <br /> */}
        <div className='center'>
          <p className="card-text">
            <span role="img" aria-label="phone">📞</span>  069 527 043
          </p>
        </div>
        {/* separator not br  */}
        <div className={"separator"} />
        <div className='center'>
          <p className="card-text">
            <a href="mailto:aurel.statnyk@gmail.com?Subject=Hi" target="_top">aurel.statnyk@gmail.com</a>
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default ImageContainer;
